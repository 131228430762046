.cart-header-div {
  padding-top: 15px;
}

.cart-header {
  padding-left: 6vw;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;

  color: var(--text-color);
}

.cart-main-div {
  background-color: var(--background-color);
}

.img-cart-elem-div {
  width: 80%;
  aspect-ratio: 1 / 1;
  background-color: #ffffff;
  border-radius: 8px;
}

.img-elem {
  width: 90%;
}

.cart-elements {
  margin-top: 24px;
}

.cart-elem-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
  letter-spacing: 0.15px;
}

.crossed {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-decoration-line: line-through;
}

.cart-elem {
  margin-top: 8px !important;
}

.choose-delivery {
  margin-top: 24px;
}

.btn-change-quantity-cart {
  background-color: var(--btn-cart-quantity);
  width: 70px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  padding: 4px;
  line-height: 18px;
  font-size: 14px;
}

.btn-change-quantity-cart > .bi::before {
  color: var(--text-color);
}

.cart-description {
  margin-top: -4px;
  color: var(--grey-description);
  font-size: 13px;
  line-height: 16px;
}

.delivery-type {
  border: var(--delivery-outline);
  border-radius: 16px;
  padding: 9px 6px 9px 6px;
  margin-right: 10px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--main-button-color);
  flex-grow: 2;
  background: none;
  transition: 0.15s;
}

.delivery-type:last-child {
  margin-right: 0;
  flex-grow: 1;
}

a {
  text-decoration: none !important;
}

.discount {
  text-decoration: line-through;
}

.checkout {
  margin-top: 30px;
}

.cart-checkout-div {
  margin-bottom: 5rem!important;
}

.delivery-type-div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  text-align: center;
}

.checkout-elem {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.checkout-elem-total {
  margin-top: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.delivery-hidden {
  margin-top: 20px;
}

.delivery-hidden-header {
  margin-bottom: 16px;
}

.deliveries > div:first-child {
  padding-right: 5px;
}

.deliveries > div:last-child {
  padding-left: 5px;
}

.delivery-type-hidden {
  width: 100%;
  color: var(--text-color);
}

.deliveries-second > div:nth-child(1) {
  padding-right: 3px;
}

.deliveries-second > div:nth-child(2) {
  padding-right: 3px;
  padding-left: 3px;
}

.deliveries-second > div:nth-child(3) {
  padding-left: 3px;
}

.delivery-address {
  margin-top: 15px;
}

.delivery-address > input,
.delivery-address > div > input {
  transition: 0.06s;
  width: 100%;
  margin-top: 18px;
  height: 56px;
  background: none;
  border-radius: 4px;
  border-width: 1px;
  padding-left: 16px;
  border-style: solid;
  border-color: var(--input-outline);
}

.delivery-address > p {
  font-size: 13px;
  color: var(--main-text-color);
  font-weight: 600;
}

.delivery-address > div > input::placeholder {
  color: var(--text-color);
}

.react-dadata__input {
  transition: 0.06s !important;
  width: 100% !important;
  margin-top: 18px !important;
  height: 56px !important;
  background: none !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  padding-left: 16px !important;
  border-style: solid !important;
  border-color: var(--input-outline) !important;
}

.react-dadata__input::placeholder {
  color: var(--text-color);
}

.react-dadata__input:focus {
  outline: none !important;
  border-color: var(--main-button-color) !important;
  border-width: 2px !important;
  box-shadow: none !important;
}

.react-dadata--highlighted {
  color: var(--main-text-color) !important;
}

.react-dadata__suggestions {
  background: var(--background-color) !important;
}

.toast-body {
  background: var(--background-color) !important;
}

.delivery-address > input:focus-visible {
  outline: none;
  border-color: var(--main-button-color);
  border-width: 2px;
}

.delivery-address > input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--text-color);
}

.delivery-type-active {
  background-color: var(--main-button-color);
  color: white;
}

.bibirevo {
  color: #676767;
}

.bibirevo-active {
  color: white;
  background-color: #676767;
}

.davidkovo {
  color: #4fafce;
}

.davidkovo-active {
  background: #4fafce;
  color: white;
}

.kuzminki {
  color: #0053A0;
}

.kuzminki-active {
  background: #0053A0;
  color: white;
}

.react-ios-time-picker {
  width: 100% !important;
}

.react-ios-time-picker-container {
  width: 100% !important;
}

.make-checkout {
  width: 100%;
  margin-top: 32px;
}

ymaps {
  color: black;
}

ymaps > * {
  color: black;
}

.ok-button:hover {
  background-color: var(--main-text-color);
}

.ok-button:active {
  background-color: #e67a00;
}

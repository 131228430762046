.profile-id {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.18px;

  color: #ff8a00;
}

.profile-username {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.profile-phone {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.profile-links {
  display: flex;
  flex-direction: column;
}

.profile-link {
  height: 72px;
  border-bottom: 1px solid var(--flex-list-border);
}

.link-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link-text-header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.link-text-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--grey-color-del);
}

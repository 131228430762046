.delivery-terms {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.delivery-terms > ul > li {
  margin-top: 30px;
}

.delivery-terms-grey {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
  color: var(--grey-color-del);
}

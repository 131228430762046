@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$grid-breakpoints: (
  xs: 0,
  sm: 300px,
  md: 380px,
  lg: 1024px,
);

@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";

.text-grey {
  color: var(--grey-description) !important;
}

body {
  min-height: 100vh;
  background-color: var(--background-color);
}

#root {
  min-height: 100vh;
  background-color: var(--background-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--text-color);
  font-family: "Montserrat", sans-serif !important;
}

.order-user-info {
  border-bottom: #ababab 1px solid;
  border-top: #ababab 1px solid;
  width: 100%;
  background: var(--bg-order-info);
}

.order-user-info > p > span {
  color: var(--grey-description);
  margin-left: 6px;
}
.main-button {
  height: 36px;
  padding: 6px 6px 6px 8px;
  border: none !important;
  border-radius: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.left-arrow {
  padding: 21px 0 0 24px;
  z-index: 1;
}

.cart-svg {
  margin: 17px 20px 0 0;
  z-index: 1;
}

.stuff-cards {
  display: flex;
}

.order-quantity {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: var(--grey-color-del);
}

.category-filter {
  background: var(--search-bg);
  border-radius: 16px;
  padding: 8px 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
}

.category-shop-filter {
  width: fit-content !important;
}

.category-active {
  background: linear-gradient(360deg, #ffa800 0%, #ff5c00 100%);
}

.category-link {
  display: contents;
}

.active-filter {
  background-color: var(--filter-active);
}

.stuff-card-div {
  margin-bottom: 15px;
}

.discountPriceColor {
  color: #FF9400;
}

@media only screen and (max-width: 360px) {
  .spec-header-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 320px) {
  .category-name {
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.anchor-basket {
  position: fixed;
  border-radius: 9999px;
  width: 70px;
  height: 70px;
  right: 30px;
  bottom: 30px;
  background: #FF9400;
}
.stuff-card {
  text-align: center;
  margin: 10px 4px 0px 4px;
  padding: 5px;
  background: var(--stuff-card-bg);
  box-shadow: var(--stuff-card-shadow);
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stuff-card > div {
  margin-bottom: 4px;
}

.stuff-card-img {
  width: 100%;
  height: 100%;
  padding: 3px;
  aspect-ratio: 1/1;
}

.stuff-card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.1px;
  width: 80%;
  margin: auto;
}

.stuff-card-desc {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 110%;
  width: 80%;
  margin: 0 auto;
}

.stuff-card-img-div {
  margin: 8px 8px 12px 8px !important;
  border-radius: 16px;
  background-color: white;
  padding: 4px;
}

.stuff-card-prices > p {
  margin: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;

  text-align: center;
  letter-spacing: 0.4px;
}

.upper-div {
  margin-top: 0;
}

.stuff-card-button {
  margin-top: 10px;
  align-items: center;
  padding: 4px;

  width: 85%;
  height: 24px;

  background: var(--btn-cart-quantity);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: var(--text-color);
  text-align: center;
  letter-spacing: 0.4px;
}

.button-change-card {
  margin: auto;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  padding: 6px;

  width: 85%;
  height: 24px;

  background: var(--btn-cart-quantity);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: var(--text-color);
  text-align: center;
  letter-spacing: 0.4px;
  background-color: #ff9400;
}

.not-available-card {
  opacity: 0.75;
}

.in-cart-button {
  background-color: var(--main-button-color) !important;
}

.bottom-div {
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 0;
}

.stuff-often-orders-div {
  border-radius: 16px;
  background-color: white;
  margin: 4px;
  padding: 4px;
}

@media only screen and (min-width: 420px) {
  .stuff-card-prices > p {
    font-size: 11px;
  }
  .stuff-card-desc {
    font-size: 12px;
  }
}

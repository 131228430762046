.cart-size {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 12px;
  top: 0;

  display: flex;
  justify-content: center;
  font-size: 12px;
  color: white;

  background: #ff9400;
  border-radius: 100px;
}

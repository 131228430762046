.shop-header {
  font-weight: 500;
  font-size: 18px;
}

.ant-select-selector {
  background: rgba(33, 33, 33, 0.08) !important;
  border-radius: 16px !important;
  border: none !important;
  height: 40px !important;
  padding: 4px 45px !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-dropdown {
  background: var(--background-color);
}

span.ant-select-selection-placeholder {
  color: var(--text-color) !important;
}

.ant-select {
  width: 100% !important;
  border: none !important;
  outline: none !important;
}

.loupe-img {
  margin-top: 10px;
}
.ant-select-selection-search-input {
  height: 40px !important;
  padding: 1px 35px !important;
  outline: none !important;
}

.ant-select-item {
  padding: 6px 0 !important;
}

.search-elem {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.text-search {
  font-weight: 600;
}

.desc-search {
}

.search-block {
  height: 60px;
}
.ant-select-arrow {
  display: none !important;
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
}
.ant-select:focus-visible {
  border: none !important;
  outline: none !important;
}

.spec-offer-div {
  width: 280px !important;
  height: 120px !important;
  border-radius: 16px;
}

.spec-offer-text {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
}

.category-block {
  border-radius: 16px;
  background-color: var(--stuff-card-bg);
  aspect-ratio: 1/1;
  box-shadow: var(--stuff-card-shadow);
}

.category-name {
  font-size: 14px;
}

.col-category {
  padding: 0 2px 0 2px !important;
}

.often-order-div {
  border-radius: 16px;
  margin: 0 0px !important;
  background: var(--stuff-card-bg);
  box-shadow: var(--stuff-card-shadow);
}

.often-img {
  width: 95%;
}

.spec-offers-swiper {
  padding-bottom: 40px !important;
}

.swiper-pagination-bullet {
  background: #2e2d2d !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #ff9400 !important;
  opacity: 1 !important;
}

.search-input {
  background: var(--bg-search);
  border-radius: 16px;
}

.stuff-button-search {
  background: #ff9400;
  color: white!important;
  font-size: 20px;
  border-radius: 6px;
  border: #ff9400 3px solid;
  padding: 6px 8px 6px 8px;
  margin-right: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  height: min-content;
  text-transform: uppercase;
}
.spec-header {
  min-height: 100px;
  height: 16vh;
  max-height: 130px;
  border-radius: 0 0 16px 16px;
}

.spec-header-title {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.spec-header-title > img {
  margin-left: 20px;
  margin-right: 5px;
}

.stuff-image-container {
  height: 55vh;
  background-color: #ffffff;
}

.stuff-image {
  height: 90%;
  max-height: 300px;
}

.text-description {
  color: var(--text-color);
  display: block;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.text-description-header {
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 36px;
  margin-bottom: 2vh;
}

.stuff-description {
  height: 45vh;
  color: var(--text-color);
  background-color: var(--background-color);
}

.stuff-button {
  background: #ff9400;
  border-radius: 16px;
  padding: 6px 6px 6px 8px;
  width: 160px;
  height: 36px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.87);

  margin-top: 30px;
}


.button-change-quantity {
  width: 160px;
  background-color: #ff9400;
  border-radius: 16px;
  padding: 6px 6px 6px 8px;
  height: 36px;
  border: none;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
  color: var(--smoke-color);
  cursor: pointer;
}

.bi-dash-lg {
  color: var(--smoke-color) !important;
  cursor: pointer;
}

.bi-plus-lg {
  color: var(--smoke-color) !important;
  cursor: pointer;
}
@media only screen and (max-width: 360px) {
  .text-description-header {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
  }
}
